html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    /*font-family: 'Josefin Sans', sans-serif;*/
    font-family: Raleway, sans-serif;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    color: #434343;
}

h1, h2, h3, h4, h5 {
    font-family: 'Josefin Sans', sans-serif;
    color: #c0dd34;
    font-weight: bold;
    text-transform: uppercase;
}

.special_heading {
    font-size: 30px;
    margin-bottom: 20px;
    width: 100%;
}

.bottom_images_holder {
    width: 100%;
    float: left;
    margin-bottom: 50px;
}

.bottom_images {
    width: 32.33%;
    height: 250px;
    float: left;
    margin: 5px 0.5%;

    background-repeat: no-repeat !important;
    background-position: 50% 50% !important;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
}

#back_button {
    color: #434343;
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 2px;
}

#back_button span {
    color: #c0dd34;
}

#back_button:hover {
    border-bottom: 2px solid #434343;;
}

h2 {
    font-size: 30px;
    margin-bottom: 20px;
}

h1 span,
p {
    line-height: 28px;
    font-size: 18px;
    color: #434343;
}

strong, b {
    font-weight: bold;
}


::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    text-transform: uppercase;
}

::-moz-placeholder { /* Firefox 19+ */
    text-transform: uppercase;
}

:-ms-input-placeholder { /* IE 10+ */
    text-transform: uppercase;
}

:-moz-placeholder { /* Firefox 18- */
    text-transform: uppercase;
}

#content, .container {
    position: relative;
    background: #fff;
    width: 100%;
    height: 100%;
}

.green {
    color: #c0dd34 !important;
}

/*Navigation*/
.top_bar {
    background-color: #c0dd34;
    height: 9px;
    width: 100%;
}

.nav_area {
    background-repeat: no-repeat !important;
    /*background-size: 100% auto !important;*/
    background-size: cover !important;
    background-position: center !important;
    color: #fff;
    height: 590px;
    width: 100%;
    /*padding-top: 47px;*/
    position: relative;
}

.nav_area_overlay {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    padding-top: 47px;
}

.nav_left {
    display: block;
    float: left;
    position: relative;
    top: 5.5px;
}

.nav_left:after {
    content: "";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.nav_button {
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    margin-right: 15px;
}

.nav_right {
    display: inline-block;
    float: right;
}

.nav_right form {
    display: inline-block;
}

.nav_right a, .nav_right span {
    color: #fff;
    -webkit-transition: background-color 0.3s linear;
    -moz-transition: background-color 0.3s linear;
    -ms-transition: background-color 0.3s linear;
    -o-transition: background-color 0.3s linear;
    transition: background-color 0.3s linear;
}

.nav_right a:hover, .nav_right span:hover  {
    background-color: rgba(192, 221, 52, 0.7);
    -webkit-transition: background-color 0.3s linear;
    -moz-transition: background-color 0.3s linear;
    -ms-transition: background-color 0.3s linear;
    -o-transition: background-color 0.3s linear;
    transition: background-color 0.3s linear;
}

.nav_container {
    width: 100%;
    margin: 0 auto;
    color: #ffffff;
    max-width: 1100px;
}

.nav_container:after {
    content: "";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.nav_contact a {
    color: #fff;
    text-decoration: none;
}

.nav_logo {
    background: url('/assets/images/logo.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 81px;
    width: 244px;
    left: 50%;
    position: absolute;
    top: 58px;
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.social_media_icon {
    cursor: pointer;
    color: #fff;
    width: 28px;
    line-height: 28px;
    display: inline-block;
    font-size: 14px;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 50%;
    margin-left: 1px;
}

.nav_list {
    border: 1px solid #fff;
    display: none;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: bold;
    padding: 0;
    list-style: none;
    margin-top: 25px;
    width: 207px;
}

.nav_list li {
    border-bottom: 1px solid #fff;
    text-align: center;
    text-transform: uppercase;
}

.nav_list li:hover {
    background-color: #c0dd34;
    -webkit-transition: background-color 0.3s linear;
    -moz-transition: background-color 0.3s linear;
    -ms-transition: background-color 0.3s linear;
    -o-transition: background-color 0.3s linear;
    transition: background-color 0.3s linear;
}

.nav_list li a {
    color: #fff;
    display: block;
    text-decoration: none;
    padding: 10px;
}

.nav_list li a:hover {
    color: #000;
    -webkit-transition: color 0.3s linear;
    -moz-transition: color 0.3s linear;
    -ms-transition: color 0.3s linear;
    -o-transition: color 0.3s linear;
    transition: color 0.3s linear;
}

.nav_list li:last-child {
    border-bottom: none;
}

.search_wrapper {
    display: inline-block;
}

.search_input {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: none;
    -webkit-appearance-border: 1px solid #fff;
    -moz-appearance-border: 1px solid #fff;
    border: 1px solid #fff;
    border-radius: 20px;
    color: #fff;
    outline: none;
    padding: 5px;
    margin-right: 10px;
}

.page_title {
    font-family: Raleway, sans-serif;
    font-size: 72px;
    font-weight: 100;
    left: 50%;
    position: absolute;
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    bottom: 15%;
    width: 100%;
}

.page_title span {
    width: 100%;
    float: left;
}

.breadcrumb_area {
    background-color: #e8e8e8;
    height: 60px;
    width: 100%;
}

.breadcrumb_container {
    text-align: right;
    padding-top: 22px;
    /*padding-right: 130px;*/
    width: 90%;
    margin: auto;
    max-width: 1100px;
}

.breadcrumb_container a {
    text-decoration: none;
    color: #000;
}

.breadcrumb_container a:hover {
    text-decoration: underline;
}

.footer_area {
    background-repeat: no-repeat !important;
    /*background-size: 100% auto !important;*/
    background-size: cover !important;
    background-position: center !important;
    color: #fff;
    font-family: 'Josefin Sans', sans-serif;
    height: 550px;
    width: 100%;
    padding-top: 60px;
    position: relative;
}

.footer_container {
    width: 90%;
    margin: 0 auto;
    max-width: 1100px;
}

.query_area {
    float: left;
    max-width: 225px;
}

.query_area h1, .get_in_touch_area h1 {
    text-transform: uppercase;
    font-weight: 100;
    font-size: 31px;
    margin-bottom: 43px;
}

.query_input {
    color: #fff;
    width: 100%;
    background: none;
    outline: none;
    border: none;
    border-radius: 0;
    font-size: 16px;
    border-bottom: 1px solid #fff;
    padding-bottom: 5px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.query_submit {
    background: none;
    color: #fff;
    cursor: pointer;
    outline: none;
    border: 1px solid #c0dd34;
    font-size: 16px;
    text-transform: uppercase;
    padding: 10px;
    width: 160px;
    border-radius: 3px;
    margin-top: 10px;
}

.query_submit:hover {
    background: #c0dd34 !important;
    color: #000;
    -webkit-transition: color 0.3s, background 0.3s linear;
    -moz-transition: color 0.3s, background 0.3s linear;
    -ms-transition: color 0.3s, background 0.3s linear;
    -o-transition: color 0.3s, background 0.3s linear;
    transition: color 0.3s, background 0.3s linear;
}

.query_textarea {
    color: #000;
    outline: none;
    border: none;
    border-radius: 0;
    font-size: 14px;
    border-bottom: 1px solid #fff;
    padding-bottom: 5px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin-top: 20px;
    text-indent: 10px;
    padding-top: 10px;
    max-width: 109% !important;
    width: 109%;
}

.query_margin {
    margin-bottom: 15px;
}

.code_margin {
    margin-bottom: 30px;
}

.footer_bottom {
    position: absolute;
    bottom: 0;
    background-color: #2e2e2e;
    width: 100%;
    height: 55px;
}

.newsletter_area {
    position: absolute;
    top: 248px;
    left: 50%;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%, -50%);
}

.newsletter_title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 15px;
    letter-spacing: 1px;
}

.newsletter_title .fa {
    margin-right: 10px;
}

.newsletter_input {
    border: none;
    outline: none;
    border-radius: 20px;
    padding: 7px;
    text-indent: 10px;
    /*width: 200px;*/
    /*margin-right: 10px;*/
    width: 96%;
}

.tick_border {
    border: 1px solid #c0dd34;
    border-radius: 50%;
    padding: 3px;
    font-size: 13px;
}

.get_in_touch_area {
    float: right;
    max-width: 230px;
    text-align: right;
}

.get_in_touch_area div {
    margin-bottom: 5px;
}

.get_in_touch_area div a {
    color: #fff;
    text-decoration: none;
}

.footer_logo {
    background-repeat: no-repeat !important;
    background-size: 100% auto !important;
    background: url(/assets/images/logo_big.png);
    width: 242px;
    height: 190px;
    margin-bottom: 70px;
}

.green_outline_button {
    background: none;
    color: #fff;
    cursor: pointer;
    outline: none;
    border: 1px solid #c0dd34;
    font-size: 16px;
    font-family: 'Josefin Sans', sans-serif;
    text-transform: uppercase;
    padding: 10px;
    width: 160px;
    border-radius: 5px;
    display: block;
    text-align: center;
    text-decoration: none;
}

.green_outline_button:hover {
    background: #c0dd34 !important;
    color: #000;
    -webkit-transition: color 0.3s, background 0.3s linear;
    -moz-transition: color 0.3s, background 0.3s linear;
    -ms-transition: color 0.3s, background 0.3s linear;
    -o-transition: color 0.3s, background 0.3s linear;
    transition: color 0.3s, background 0.3s linear;
}

.green_button {
    background: #c0dd34;
    color: #000;
    cursor: pointer;
    outline: none;
    border: 1px solid #c0dd34;
    font-size: 16px;
    font-family: 'Josefin Sans', sans-serif;
    text-transform: uppercase;
    padding: 10px;
    width: 160px;
    border-radius: 3px;
    display: block;
    text-align: center;
    text-decoration: none;
}

.green_button:hover {
    color: #fff !important;
}

.get_in_touch_area .green_outline_button {
    float: right
}

.footer_links {
    color: #959595;
    margin-top: 22px;
    font-size: 11px;
    font-family: Raleway, sans-serif
}

.footer_links a {
    color: #959595;
    text-decoration: none;
    padding-right: 5px;
    margin-right: 5px;
    border-right: 1px solid #959595;
}

.footer_links a:hover {
    text-decoration: underline;
}

.footer_links .no_underline:hover {
    text-decoration: none;
}

.footer_links a:last-child {
    border-right: none;
}

.footer_social_media {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 11px;
    width: 300px;
    margin: auto;
    -webkit-transition: background-color 0.3s linear;
    -moz-transition: background-color 0.3s linear;
    -ms-transition: background-color 0.3s linear;
    -o-transition: background-color 0.3s linear;
    transition: background-color 0.3s linear;
}

.footer_social_media a:hover {
    background-color: rgba(192, 221, 52, 0.7);
    -webkit-transition: background-color 0.3s linear;
    -moz-transition: background-color 0.3s linear;
    -ms-transition: background-color 0.3s linear;
    -o-transition: background-color 0.3s linear;
    transition: background-color 0.3s linear;
}

.back_to_top {
    float: right;
    margin-top: -17px;
    cursor: pointer;
}

.back_to_top_button {
    cursor: pointer;
    outline: none;
    border-top: 1px solid #c0dd34;
    border-left: 1px solid #c0dd34;
    border-right: 1px solid #c0dd34;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-transform: uppercase;
    padding: 10px 25px;
    font-size: 13px;
}

.back_to_top_button:hover {
    background: #c0dd34;
    color: #000;
    -webkit-transition: color 0.3s, background 0.3s linear;
    -moz-transition: color 0.3s, background 0.3s linear;
    -ms-transition: color 0.3s, background 0.3s linear;
    -o-transition: color 0.3s, background 0.3s linear;
    transition: color 0.3s, background 0.3s linear;
}

.back_to_top_button .fa {
    margin-left: 36px;
    font-size: 16px;
}

.content_container {
    width: 90%;
    max-width: 1100px;
    margin: 0 auto;
    padding-bottom: 100px;
    padding-top: 100px;
    display: table;
    position: relative;
}

.content_title {
    color: #c0dd34;
    font-size: 38px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 40px;
}

.content_text {
    width: 70%;
    margin-bottom: 30px;
}

.content_text p {
    margin-bottom: 20px;
}

.history_text {
    float: left;
    width: 65%;
}

.history_text p {
    margin-bottom: 20px;
}

.history_image {
    background-repeat: no-repeat !important;
    background-size: 100% auto !important;
    /*background: url(/assets/images/old_logo.jpg);*/
    margin-bottom: 70px;
    width: 270px;
    height: 240px;
    float: right;
}

.history_image_fade {
    background-repeat: no-repeat !important;
    background-size: 100% auto !important;
    width: 270px;
    height: 240px;
    float: right;
    display: none;
}

.testimonial_area {
    background-size: cover !important;
    background-position: center !important;
    background: url(/assets/images/testimonal_background.jpg);
    width: 100%;
    height: 570px;
}

.testimonial_text {
    color: #fff;
    float: right;
    text-align: right;
    width: 50%;
    text-transform: uppercase;
}

.testimonial_text p {
    color: #fff;
    margin-bottom: 20px;
    line-height: 22px;
}

.testimonial_slide {
    width: 100%;
}

.testimonial_slide a {
    color:#fff;
}

.testimonial_person {
    text-transform: none;
}

.testimonial_left {
    float: left;

}

.testimonial_title {
    color: #c0dd34;
    font-size: 38px;
    text-transform: uppercase;
    font-weight: bold;
}

.example-pager {
    float: left;
}

.example-pager span {
    cursor: pointer;
    font-size: 0px;
    width: 10px;
    height: 10px;
    background-color: #000;
    border: 2px solid #fff;
    border-radius: 50%;
    display: inline-block;
    margin-right: 7px;
}

.example-pager .cycle-pager-active {
    background-color: #fff;
}

.quotes {
    background-size: cover !important;
    background-position: center !important;
    background: url(/assets/images/quotes.png);
    width: 83px;
    height: 65px;
    float: left;
    margin-right: 33px;
    margin-top: 8px;
}

.career_title {
    color: #808080;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
    white-space: normal;
    width: 100%;
    text-transform: uppercase;
}

.career_item {
    padding: 0 50px 0 0;
    width: 525px;
    margin-right: 1em;
    white-space: normal;
    vertical-align: top;
}

.career_body {
    width: 100%;
    white-space: normal;
}

.career_body p {
    width: 100%;
    white-space: normal;
    margin-bottom: 30px;
}

div.home-block {
    padding: 0 50px;
    width: 300px;
    margin-right: 1em;
    background: lightgrey;
    white-space: normal;
    vertical-align: top;
}

.read_more, .send_your_cv {
    color: #c0dd34;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
}

.read_more:hover, .send_your_cv:hover {
    text-decoration: underline;
}

.career_item .read_more {
    border-right: 1px solid #c0dd34;
    padding-right: 10px;
    margin-right: 5px;
}

.career_container {
    position: relative;
}

.career_carousel {
    max-width: 1110px;
    margin: auto;
}

.career_next, .career_prev {
    color: #fff;
    cursor: pointer;
    background-color: #c0dd34;
    position: absolute;
    top: 50%;
}

.career_next:hover, .career_prev:hover {
    background-color: #2e2e2e;
}

.career_next {
    right: -15px;
    width: 22px;
    height: 30px;
    font-size: 22px;
    padding-left: 11px;
    padding-top: 5px;
}

.career_prev {
    left: -50px;
    width: 28px;
    height: 30px;
    font-size: 22px;
    padding-left: 5px;
    padding-top: 5px;
}

.carousel_expand {
    width: 1110px !important;
    padding-right: 73px !important;
    white-space: nowrap !important;
    margin-right: 0 !important;
}

.job_offer_title {
    color: #808080;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    width: 100%;
    text-transform: uppercase;
}

.job_offer_text {
    font-size: 14px;
}

.job_offer_text p {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
}

.expand_careers {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    top: 0;
    display: none;
    overflow-y: scroll;
    z-index: 999;
}

.expand_careers .content_container {
    /*padding-top: 50px;*/
    padding-top: 25px;
    padding-bottom: 0;
}

.close_careers {
    right: 0;
    font-size: 22px;
    position: absolute;
    background: #c0dd34;
    padding: 3px 7px;
    cursor: pointer;
    color: #fff;
}

.form_input {
    -moz-appearance: none;
    -webkit-appearance: none;
    border: 1px solid #363636;
    border-radius: 8px;
    font-size: 13px;
    outline: none;
    padding: 15px;
    margin-bottom: 10px;
    /*text-transform: uppercase;*/
}

.form_submit {
    -moz-appearance: none;
    -webkit-appearance: none;
    border: 1px solid #c0dd34;
    background: #c0dd34;
    border-radius: 8px;
    color: #000;
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    outline: none;
    padding: 15px;
    margin-top: 20px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
}

.form_submit:hover {
    color: #fff;
}

.registration_form {
    max-width: 600px;
}

.registration_form .form_input {
    /* width: 440px;
     margin: auto; */
    width: 80%;
    margin-bottom: 20px;
}

.registration_form .form_submit {
    width: 96%;
}

.registration_form h3 {
    float: left;
    margin-bottom: 10px;
}

.registration_left {
    float: left;
    width: 50%;
}

.registration_right {
    float: right;
    width: 50%;
}

.location_title {
    color: #808080;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.location_text p {
    line-height: 24px;
}

.address_area {
    margin-top: 70px;
}

.address_item {
    display: table;
    margin-bottom: 50px;
    width: auto;
}

.address_left {
    float: left;
}

.address_right {
    float: right;
}

.map_bg {
    background-size: 100% auto !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background: url(/assets/images/map_bg.png);
}

.map_pin {
    background-repeat: no-repeat !important;
    background-size: 100% auto !important;
    background: url(/assets/images/map_pin.png);
    height: 60px;
    width: 35px;
    margin-right: 15px;
}

.benefits_area {
    display: table;
}

.benefit_title {
    color: #808080;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 25px;
    font-weight: bold;
    float: left;
    margin-bottom: 30px;
    text-transform: uppercase;
    width: 100%;
}

.benefit_subtitle {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.benefit_text {
    margin-bottom: 20px;
}

.benefit_text:last-child {
    margin-bottom: 40px;
}

.benefit_left {
    float: left;
    width: 70%;
}

.benefit_right {
    float: right;
    margin-top: 3px;
}

.benefit_right .green_button, .benefit_right .green_outline_button {
    color: #000;
    margin-bottom: 10px;
    width: 190px;
    font-size: 14px;
}

.smart_login_area {
    margin-bottom: 4px;
    margin-top: -50px;
    width: 100%;
}

.smart_login_area:after {
    content: "";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.smart_login_area .content_container {
    padding-top: 0;
    padding-bottom: 0;
}

.smart_login_box {
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: cover !important;
    background: url(/assets/images/header_image.jpg);
    width: calc(100% / 5 - 4px);
    height: 300px;
    float: left;
    position: relative;
    margin-right: 5px;
}

.smart_login_box_overlay {
    background-color: rgba(56, 56, 56, 0.5);
    width: 100%;
    height: 100%;
}

.smart_login_box:last-child {
    margin-right: 0;
}

.smart_login_box_hover {
    background-color: rgba(56, 56, 56, 0.9);
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    position: relative;
    -webkit-transition: visibility 0.5s, opacity 0.5s linear;
    -moz-transition: visibility 0.5s, opacity 0.5s linear;
    -ms-transition: visibility 0.5s, opacity 0.5s linear;
    -o-transition: visibility 0.5s, opacity 0.5s linear;
    transition: visibility 0.5s, opacity 0.5s linear;
}

.smart_login_box_hover_container {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.smart_login_box:hover .smart_login_box_title {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 0.5s, opacity 0.5s linear;
    -moz-transition: visibility 0.5s, opacity 0.5s linear;
    -ms-transition: visibility 0.5s, opacity 0.5s linear;
    -o-transition: visibility 0.5s, opacity 0.5s linear;
    transition: visibility 0.5s, opacity 0.5s linear;
}

.smart_login_box:hover .smart_login_box_hover {
    visibility: visible;
    opacity: 1;
    -webkit-transition: visibility 0.5s, opacity 0.5s linear;
    -moz-transition: visibility 0.5s, opacity 0.5s linear;
    -ms-transition: visibility 0.5s, opacity 0.5s linear;
    -o-transition: visibility 0.5s, opacity 0.5s linear;
    transition: visibility 0.5s, opacity 0.5s linear;
}

.login_box_title {
    color: #c0dd34;
    font-size: 21px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.login_box_text {
    color: #fff;
    line-height: 25px;
    font-size: 16px;
    margin: auto;
    margin-bottom: 20px;
    text-align: center;
    width: 80%;
}

.login_box_text p {
    color: #fff;
    line-height: 25px;
    font-size: 16px;
}

.login_box_text a {
    color:#fff;
}

.login_box_link {
    color: #c0dd34;
    display: block;
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
}

.login_box_link:hover {
    text-decoration: underline;
}

.smart_login_box_title {
    background: none;
    color: #fff;
    cursor: pointer;
    outline: none;
    border: 2px solid #c0dd34;
    font-size: 16px;
    text-transform: uppercase;
    padding: 10px;
    /*width: 180px;*/
    width: 200px;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    margin: auto;
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: visible;
    opacity: 1;
    -webkit-transition: visibility 0.5s, opacity 0.5s linear;
    -moz-transition: visibility 0.5s, opacity 0.5s linear;
    -ms-transition: visibility 0.5s, opacity 0.5s linear;
    -o-transition: visibility 0.5s, opacity 0.5s linear;
    transition: visibility 0.5s, opacity 0.5s linear;
}

.travel_update_area {
    background-color: #464646;
    position: relative;
    width: 100%;
}

.travel_slide {
    display: block;
    width: 100%;
}

.travel_icon {
    float: left;
    font-size: 130px;
    margin-left: 2px;
}

.travel_update_text_area {
    width: 85%;
    float: right;
}

.travel_update_title {
    color: #fff;
    text-transform: uppercase;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
}

.travel_update_text {
    color: #fff;
    margin-bottom: 25px;
}

.travel_update_text p {
    color: #fff;
}

.travel_update_link {
    color: #c0dd34;
    cursor: pointer;
    display: block;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
}

.travel_update_link:hover {
    text-decoration: underline;
}

.alert_prev {
    left: 0;
    width: 28px;
    height: 30px;
    font-size: 22px;
    padding-left: 5px;
    padding-top: 5px;
    color: #464646;
    cursor: pointer;
    background-color: #c0dd34;
    position: absolute;
    top: 44%;
}

.alert_next {
    right: 0;
    width: 22px;
    height: 30px;
    font-size: 22px;
    padding-left: 11px;
    padding-top: 5px;
    color: #464646;
    cursor: pointer;
    background-color: #c0dd34;
    position: absolute;
    top: 44%;
}

.travel_resource_area {
    background-size: cover !important;
    background-position: center !important;
    background: url(/assets/images/travel_resource_bg.jpg);
    text-align: center;
}

.travel_resource_area .content_title {
    color: #464646;
    text-align: left;
}

.travel_resource_item {
    color: #000;
    border: 1px solid;
    border-radius: 5px;
    padding: 10px;
    width: 240px;
    margin-right: 10px;
    display: inline-block;
    text-align: center;
    margin-bottom: 10px;
    text-decoration: none;
}

.travel_resource_item:hover {
    color: #c0dd34;
    background: #656463;
    border: 1px solid #656463;
    -webkit-transition: color 0.3s, background 0.3s linear;
    -moz-transition: color 0.3s, background 0.3s linear;
    -ms-transition: color 0.3s, background 0.3s linear;
    -o-transition: color 0.3s, background 0.3s linear;
    transition: color 0.3s, background 0.3s linear;
}

.travel_service_area {
    width: 100%;
    display: table;
    border-bottom: 5px solid #e8e8e8;
    position: relative;
}

.travel_service_area:first-child {
    border-top: 5px solid #fff;
}

.travel_service_carousel {
    /*max-width: 1110px;*/
    width: 70%;
    margin: auto;
}

.travel_service_image {
    float: left;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
    height: 330px;
    width: 20%;
    /*margin-right: 75px;*/
    position: relative;
}

.travel_service_image_text {
    background: none;
    color: #fff;
    border: 2px solid #c0dd34;
    font-size: 16px;
    text-transform: uppercase;
    padding: 10px;
    width: 160px;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    margin: auto;
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.travel_service_carousel_item {
    padding: 0 50px 0 0;
    width: 300px;
    margin-right: 1em;
    white-space: normal;
    vertical-align: top;
}

.travel_service_title {
    color: #808080;
    font-size: 27px;
    font-weight: bold;
    margin-top: 45px;
    margin-bottom: 20px;
    white-space: normal;
    width: 100%;
    text-transform: uppercase;
}

.travel_service_text {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 30px;

    max-height: 120px;
    overflow: hidden;
}

.service_next, .service_prev {
    color: #fff;
    cursor: pointer;
    background-color: #c0dd34;
    position: absolute;
    top: 43%;
}

.service_next:hover, .service_prev:hover {
    background-color: #2e2e2e;
}

.form_buttons {
    color: #000000 !important;
    float: left !important;
    margin-right: 2.5%;
}

.service_next {
    right: 0;
    width: 22px;
    height: 30px;
    font-size: 22px;
    padding-left: 11px;
    padding-top: 5px;
    /*top: 50%;*/
}

.service_prev {
    right: -33px;
    width: 28px;
    height: 30px;
    font-size: 22px;
    padding-left: 5px;
    padding-top: 5px;
}

/*.service_prev .disabled {*/
/*display: none;*/
/*}*/

/*.service_next .disabled {*/
/*display: none;*/
/*}*/
.carousel_control div.disabled {
    display: none;
}

.login_form .form_input {
    width: 40% !important;
    margin-right: 2.5%
}

.login_form .form_submit {
    width: 22% !important;
    margin-right: 2.5%
}

.forgot_password {
    color: #c0dd34;
    display: block;
    font-family: "Josefin Sans", sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    text-decoration: none;
    text-transform: uppercase;
}

.forgot_password:hover {
    text-decoration: underline;
}

#accordion h3 {
    color: #808080;
    cursor: pointer;
    font-size: 27px;
    font-weight: bold;
    margin-top: 45px;
    margin-bottom: 20px;
    white-space: normal;
    width: auto;
    text-transform: uppercase;
    outline: none;
    display: table;
}

#accordion h3 .fa {
    margin-right: 20px;
}

.ui-accordion-content {
    padding-top: 30px;
}

.ui-icon.iconOpen {
    background: url('/assets/images/down_icon.png') no-repeat;
    background-size: 100% auto;
    width: 40px;
    height: 40px;
    float: right;
    margin-left: 10px;
    margin-top: 3px;
}

.ui-icon.iconClosed {
    background: url('/assets/images/up_icon.png') no-repeat;
    background-size: 100% auto;
    width: 40px;
    height: 40px;
    float: right;
    margin-left: 10px;
    margin-top: 3px;
}

.client_lounge_left {
    float: left;
    width: 70%;
}

.client_lounge_left .content_text {
    width: 100%;
}

.client_lounge_right {
    float: right;
}

.document_area {
    display: table;
    width: 100%;
}

.client_lounge_right .green_outline_button {
    color:#000;
    margin-bottom: 20px;
}

.document_item {
    color: #000;
    border: 1px solid;
    border-radius: 5px;
    padding: 10px;
    width: 240px;
    margin-right: 8px;
    display: inline-block;
    text-align: center;
    margin-bottom: 10px;
    text-decoration: none;
    -webkit-transition: border 0.3s, background 0.3s linear;
    -moz-transition: border 0.3s, background 0.3s linear;
    -ms-transition: border 0.3s, background 0.3s linear;
    -o-transition: border 0.3s, background 0.3s linear;
    transition: border 0.3s, background 0.3s linear;
}

.document_item:hover {
    background: #c0dd34;
    border: 1px solid #c0dd34;
    -webkit-transition: border 0.3s, background 0.3s linear;
    -moz-transition: border 0.3s, background 0.3s linear;
    -ms-transition: border 0.3s, background 0.3s linear;
    -o-transition: border 0.3s, background 0.3s linear;
    transition: border 0.3s, background 0.3s linear;
}

#message {
    max-width: 100%;
    max-height: 75px;
    font-family: Raleway, sans-serif;
}

.smart_form {
    width: 100%;
}

.smart_form textarea {
    border: 1px solid #000;
    border-radius: 8px;
    color: #000;
    font-family: Raleway, sans-serif;
    height: 145px;
    margin-left: 40px;
    margin-top: 118px;
    padding: 10px;
    text-indent: 10px;
    width: 40%;

    max-height: 145px;
    max-width: 40%;
}

.smart_form .form_input {
    display: block;
    width: 49%;
    float: left;
}

.smart_form .form_submit {
    display: block;
    width: 49%;
    float: left;
}

.smart_form_left {
    float: left;
}

.smart_form_right {
    float: right;
}

.account_form label {
    margin-bottom: 5px;
    display: block;
}

.account_form input {
    display: block;
    width: 40%;
    margin-bottom: 20px;
}

.account_form p {
    margin-bottom: 10px;
}

.send_cv_area {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.7);
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.close_cv_area {
    color: #fff;
    cursor: pointer;
    font-size: 34px;
    position: absolute;
    top: 10px;
    right: 17px;
}

.cv_form {
    left: 50%;
    position: absolute;
    top: 50%;
    -moz-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    text-align: center;
}

.cv_form .form_input, .cv_form .form_submit {
    display: block;
    width: 100%;
}

.recent_news_2 {
    width: 100%;
}

.news_article {
    margin-bottom: 30px;
}

.news_article_2 {
    width: 21%;
    width: -moz-calc(21% + 4px);
    width: -webkit-calc(21% + 4px);
    width: -o-calc(21% + 4px);
    width: calc(21% + 4px);

    float: left;
    border: 2px solid #eeeeee;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    overflow: hidden;
    margin-right: 3%;
    margin-bottom: 40px;
    padding: 0 !important;
}

.news_article_2:hover {
    border: 2px solid #cccccc;
}

.news_article_2 .img_holder {
    padding: 60px 5% 0 5%;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    color: #ffffff;
    position: relative;
    float: left;
    width: 90%;
    height: 130px;
    background-repeat: no-repeat !important;
    background-position: 50% 50% !important;

    -webkit-border-radius: 8px 8px 0 0;
    -moz-border-radius: 8px 8px 0 0;
    border-radius: 8px 8px 0 0;
}

.news_article_2 .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 0;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.news_article_2:hover .overlay {
    background: rgba(0,0,0,0.6);
}

.news_article_2 .news_title span {
    width: 100%;
    float: left;
    font-size: 15px;
    color: #9A9A9A;
    margin-bottom: 10px;
    text-transform: capitalize;
    font-weight: normal;
    font-family: arial;
}

.news_article_2 .news_title {
    width: 90%;

    min-height: 130px;
    max-height: 130px;
    color: #434343;
    font-size: 22px;
    padding: 10px 5%;
    float: left;
    margin: 0;
}

.news_article_2 .news_body {
    width: 90%;
    min-height: 100px;
    max-height: 100px;
    margin-bottom: 0;
    padding: 10px 5%;
    float: left;
    color: #434343;
    text-decoration: none;
}

.news_article_2 .read_more {
    width: 90%;
    padding: 0 5% 10px 5%;
    float: left;
}

.news_article_2:hover .read_more {
    text-decoration: underline;
}

.share-buttons {
    width: 100%;
    float: left;
}

.share-buttons li {
    width: 40px;
    height: 40px;
    float: left;
    list-style: none;

    padding-left: 0 !important;
    padding-right: 10px;
}

.share-buttons li a {
    width: 100%;
    height: 100%;
    float: left;
    text-decoration: none;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    background: #c0dd34;
    position: relative;
}

.share-buttons li a i {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #000000;

    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);

    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.share-buttons li a:hover i {
    opacity: 0.6;
}

.alerts_area_2 {
    max-width: 1100px;
    width: 90% !important;
    top: 30px;
    left: 50%;
    -moz-transform: translate(-50%,0);
    -webkit-transform: translate(-50%,0);
    -o-transform: translate(-50%,0);
    transform: translate(-50%,0);
    float: left;
    text-align: left;
}

.clear {
    width: 100%;
    float: left;
}

.news_text p {
    margin-bottom: 20px;
}

.news_text ul {
    margin-bottom: 20px;
    list-style-position: inside;
}

.news_text ul li {
    margin-bottom: 5px;
    padding-left: 20px;
}

.recent_news {
    margin-bottom: 60px;
    display: table;
}

.prev_page, .next_page {
    color: #c0dd34;
    font-weight: bold;
    text-transform: uppercase;
}

.prev_page {
    float: left;
}

.next_page {
    float:right;
}

.search_list {
    list-style: none inside;
    margin-top: 10px;
}

.search_list li {
    margin-bottom: 10px;
}

.search_list li a {
    text-decoration: none;
    color:#c0dd34;
    font-weight: bold;
}

.search_list li a:hover {
    text-decoration: underline;
}

legend {
    color:#c0dd34;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
}

fieldset {
    margin-bottom: 20px;
}

.field {
    float:left;
    margin-right: 10px;
    width: 60%;
}

.input input {
    -moz-appearance: none;
    -webkit-appearance: none;
    border: 1px solid #363636;
    border-radius: 8px;
    font-size: 13px;
    outline: none;
    padding: 15px;
    margin-bottom: 10px;
    width: 100%;
}

.textual textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    border: 1px solid #363636;
    border-radius: 8px;
    font-size: 13px;
    outline: none;
    padding: 15px;
}

.field select {
    border: 1px solid #363636;
    border-radius: 8px;
    font-size: 13px;
    outline: none;
    padding: 15px;
    margin-bottom: 10px;
}

.heading {
    margin-bottom: 10px;
}

.field-107 {
    width: 100%;
}

.field-107 .input input {
    width: 100%;
}

#ui-datepicker-div {
    background: #fff;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 10px;
    display: none;
}

.ui-state-default {
    color: #c0dd34;
    padding: 5px;
    text-align: center;
    display: inline-block;
    font-weight: bold;
}

.ui-datepicker-prev {
    cursor: pointer;
    display: inline-block;
    margin-top: 10px;
    margin-left: 5px;
}

.ui-datepicker-next {
    cursor: pointer;
    float: right;
    margin-top: 10px;
    margin-right: 5px;
}

.ui-datepicker-title {
    font-weight: bold;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.download_pdf {
    color: #c0dd34;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    text-decoration: underline;
}

.download_popup_area {
    display: none;
    position: fixed;
    top:0;
    left:0;
    background: rgba(0,0,0, 0.7);
    width: 100%;
    height: 100%;
    z-index: 999;

}

.close_popup {
    color: #c0dd34;
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 35px;
    font-size: 34px;
    z-index: 999;
}

.download_popup {
    background: #fff;
    width: 500px;
    height: 215px;
    position: absolute;
    left: 50%;
    top: 50%;
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 10px;
}

.twitter_icon {
    position: relative;
}

.twitter_popup {
    background: #fff;
    display: none;
    width: 140px;
    height: 100px;
    position: absolute;
    left: -55px;
    top: 36px;
    border-radius: 10px;
    z-index: 999;
}

.twitter_popup_footer {
    background: #fff;
    display: none;
    width: 140px;
    height: 100px;
    position: absolute;
    left: 80px;
    top: -108px;
    border-radius: 10px;
    z-index: 999;
}

.twitter_popup_item {
    color:#000;
    margin-top: 14px;
}

.twitter_popup_item a:hover {
    background: none;
    text-decoration: underline !important;
}

.twitter_popup_item a span:hover {
    background: none;
    text-decoration: underline !important;
}

.twitter_popup_icon {
    background: #c0dd34;
    margin-right: 10px;
}

.popup_icon {
    display:table;
    margin-left: 10px;
    text-decoration: none;
}

.download_popup .form_input {
    width: 70%;
    margin: auto;
    display: block;
    margin-bottom: 20px;
}

.download_popup .form_submit {
    width: 76%;
    margin: auto;
    display: block;
}

.download_popup p {
    color: #c0dd34;
    font-size: 15px;
    width: 80%;
    margin: auto;
    margin-top: 15px;
    text-align: center;
    line-height: 23px;
    margin-bottom: 15px;
    font-weight: bold;
}

.homepage_link {
    color: #c0dd34;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    text-decoration: underline;
}

.subscribe_button {
    background: none;
    color: #fff;
    cursor: pointer;
    outline: none;
    border: 1px solid #c0dd34;
    font-size: 16px;
    text-transform: uppercase;
    padding: 10px;
    width: 100%;
    border-radius: 3px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
}

.subscribe_button:hover {
    background: #c0dd34 !important;
    color: #000;
    -webkit-transition: color 0.3s, background 0.3s linear;
    -moz-transition: color 0.3s, background 0.3s linear;
    -ms-transition: color 0.3s, background 0.3s linear;
    -o-transition: color 0.3s, background 0.3s linear;
    transition: color 0.3s, background 0.3s linear;
}

.career_intro_text {
    margin-bottom: 50px;
}

.career_intro_text p {
    margin-bottom: 10px;
}

.newsletter-form p {
    color: #fff !important;
}

input#preferredKitten { display: none; }

.btn .add .icon {
    display: none;
}

.required {
    position: relative;
}

.required::after {
    content: "*";
    color: red;
    font-size: 34px;
    position: absolute;
    top: -7px;
}

.errors {
    max-width: 160px;
    font-size: 13px;
    color: red;
    margin-bottom: 15px;
    list-style: none;
}

.query_input_query {
    background: none;
    border: none;
    position: absolute;
    z-index: 0;
    left: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
}

@media only screen and (min-width: 0px) and (max-width: 980px) {
    .news_article_2 {
        width: 29%;
        width: -moz-calc(29% + 4px);
        width: -webkit-calc(29% + 4px);
        width: -o-calc(29% + 4px);
        width: calc(29% + 4px);
    }
}

@media only screen and (min-width: 0px) and (max-width: 800px) {
    .client_lounge_right {
        position: relative !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
    .news_article_2 {
        width: 45%;
        width: -moz-calc(45% + 4px);
        width: -webkit-calc(45% + 4px);
        width: -o-calc(45% + 4px);
        width: calc(45% + 4px);
    }
}

@media only screen and (min-width: 0px) and (max-width: 480px) {
    .news_article_2 {
        width: 100%;
        width: -moz-calc(100% + 4px);
        width: -webkit-calc(100% + 4px);
        width: -o-calc(100% + 4px);
        width: calc(100% + 4px);
        margin-right: 0;
    }
}