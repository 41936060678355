.homepage_header {
    width: 100%;
	height: 100px;
    min-height: 45pc;
    position: relative;
}

.homepage_header_slider {
    width: 100%;
    position: absolute;
    height: 100%;
    z-index: 1;
}

.homepage_header_item {
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.homepage_header_content {
    background-color: rgba(25, 25, 25, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.homepage_header_content_inside {
    max-width: 1100px;
    margin: 0 auto 0 auto;
    height: 100%;
    position: relative;
}

.homepage_header_content_area {
    left: 50%;
    position: absolute;
    top: 50%;
    -moz-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%, -50%);
    width: 100%;
}

.logo_homepage {
    width: 100%;
    position: relative;
    text-align: center;
}

.logo_homepage img {
    display: block;
    margin: 0 auto 0 auto;
    width: 250px;
}

.content_one_homepage {
    position: relative;
}

.static_title {
    position: absolute;
    top: 0;
    font-size: 50px;
    color: #FFF;
    text-align: center;
    width: 100%;
    font-family: 'Raleway', sans-serif;
    font-weight: 100;
}

.content_one_homepage p{
    width: 100%;
    text-align: center;
    color: #ffffff;
    /*font-size: 55px;
    margin-top: 25px;
    margin-bottom: 65px; */
    font-size: 40px;
    margin-top: 55px;
    margin-bottom: 65px;
    font-family: 'Raleway', sans-serif;
    font-weight: 100;
    line-height: 65px;
}

.content_two_homepage {
    width: 100%;
    position: relative;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 25px;
    font-family: 'Josefin Sans', sans-serif;
}

.content_two_homepage p {
    color: #fff;
}

.content_two_homepage span {
    font-size: 20px;
}

.homepage_pager {
    text-align: center;
}

.homepage_pager span {
    cursor: pointer;
    font-size: 0px;
    width: 10px;
    height: 10px;
    border: 1px solid #fff;
    border-radius: 50%;
    display: inline-block;
    margin-right: 7px;
}

.homepage_pager .cycle-pager-active {
    background-color: #fff;
}

.alerts_area {
    /*cursor: pointer;*/
    width: calc(100% - 4px);
    height: 115px;
    /*margin-bottom: 20px;*/
    background: #464646;
    border: 2px solid #c0dd34;
    border-radius: 10px;
    position: relative;
}

.close_alerts {
    color: #c0dd34;
    position: absolute;
    top: 4px;
    right: 10px;
    font-size: 24px;
    z-index: 999;
}

.pager_area {
    width: 100%;
    height: 100px;
}

.homepage_travel_slide {
    display: block;
    width: 100%;
	height: 103px;
}

.homepage_travel_icon {
    float: left;
    font-size: 52px;
    margin-left: 30px;
    margin-top: 10px;
}

.homepage_travel_update_text_area {
    width: 88%;
    float: right;
    padding-right: 10px;
    text-decoration: none;
}

.homepage_travel_update_title {
    color: #c0dd34;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 13px;
}

.homepage_travel_update_text {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
}

.homepage_travel_update_text p {
    color: #fff;
    font-size: 12px;
    line-height: 15px;
}

.homepage_travel_update_link {
    color: #c0dd34;
    cursor: pointer;
    display: block;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
}

.homepage_travel_update_link:hover {
    text-decoration: underline;
}

.navigation_homepage {
    width: 100%;
    position: relative;
    text-align: center;
}

.navigation_homepage:after {
    content: "";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.navigation_homepage a {
    display: inline-block;
    margin-bottom: 20px;
    margin-right: 10px;
    text-transform: uppercase;
    border: solid 1px #c0dd34;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-decoration: none;
    color: #ffffff;
    width: 14.5%;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-transition: color 0.3s, background 0.3s linear;
    -moz-transition: color 0.3s, background 0.3s linear;
    -ms-transition: color 0.3s, background 0.3s linear;
    -o-transition: color 0.3s, background 0.3s linear;
    transition: color 0.3s, background 0.3s linear;
}

.navigation_homepage a:last-child {
    margin-right: 0;
}

.navigation_homepage a:hover {
    color: #000;
    background: #c0dd34;
    -webkit-transition: color 0.3s, background 0.3s linear;
    -moz-transition: color 0.3s, background 0.3s linear;
    -ms-transition: color 0.3s, background 0.3s linear;
    -o-transition: color 0.3s, background 0.3s linear;
    transition: color 0.3s, background 0.3s linear;
}

.nav_container_homepage {
    width: 100%;
    margin: 0 auto;
    color: #ffffff;
    max-width: 1100px;
    padding-top: 40px;
}

.nav_container_homepage:after {
    content: "";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.welcome_text {
    float: left;
    width: 55%;
    /*width: 100%; */
    position: relative;
    z-index:10;
}

.welcome_text p {
    margin-bottom: 10px;
}

.welcome_image {
    /*background-size: cover !important;*/
    /*background-position: center !important;*/
    /*width: 508px;*/
    /*height: 310px;*/
    /*position: absolute;*/
    /*right: 0;*/
    /*top: 50%;*/
    /*-moz-transform: translate(0%, -50%);*/
    /*-webkit-transform: translate(0%, -50%);*/
    /*-o-transform: translate(0%, -50%);*/
    /*transform: translate(0%, -50%);*/
    /*z-index: 0;*/

    background-size: cover !important;
    background-position: center !important;
    width: 55%;
    height: 576px;
    position: absolute;
    right: 0;
    top: 54.5%;
    -moz-transform: translate(0%, -50%);
    -webkit-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    z-index: 0;

}

.welcome_image:before{
    content: " ";
    position: absolute;
    width: 300px;
    height: 120%;
    background: #fff;
    left: -120px;
    border-radius: 50%;
    top: 50%;
    -moz-transform: translate(0%, -50%);
    -webkit-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}

.homepage_travel_services {
    margin-bottom: 20px;
    width: 100%;
}

.homepage_travel_services:after {
    content: "";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.travel_service_item {
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: cover !important;
    background: url(/assets/images/header_image.jpg);
    width: calc(100% / 4 - 4px);
    height: 340px;
    margin-right: 5px;
    float: left;
    position: relative;
}

.travel_service_item:last-child {
    margin-right: 0;
}
.travel_service_item_overlay {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
}

.travel_service_item_hover {
    background-color: rgba(56, 56, 56, 0.9);
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    position: relative;
    -webkit-transition: visibility 0.5s, opacity 0.5s linear;
    -moz-transition: visibility 0.5s, opacity 0.5s linear;
    -ms-transition: visibility 0.5s, opacity 0.5s linear;
    -o-transition: visibility 0.5s, opacity 0.5s linear;
    transition: visibility 0.5s, opacity 0.5s linear;
}

.travel_service_item_hover_container {
    left: 50%;
    position: absolute;
    top: 50%;
    -moz-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%, -50%);
    width: 100%;
}

.travel_service_item:hover .travel_service_item_hover {
    visibility: visible;
    opacity: 1;
    -webkit-transition: visibility 0.5s, opacity 0.5s linear;
    -moz-transition: visibility 0.5s, opacity 0.5s linear;
    -ms-transition: visibility 0.5s, opacity 0.5s linear;
    -o-transition: visibility 0.5s, opacity 0.5s linear;
    transition: visibility 0.5s, opacity 0.5s linear;
}

.service_hover_title {
    color: #c0dd34;
    font-size: 21px;
    text-align: center;
    text-transform: uppercase;
    /*padding-top: 30px;*/
    margin-bottom: 30px;
}

.service_hover_text {
    color: #fff;
    margin: auto;
    margin-bottom: 30px;
    line-height: 28px;
    font-size: 18px;
    text-align: center;
    width: 80%;
}

.service_hover_text p {
    color:#fff;
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 10px;
}

.service_hover_link {
    color: #c0dd34;
    display: block;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
}

.service_item {
    background: none;
    color: #fff;
    cursor: pointer;
    outline: none;
    border: 2px solid #c0dd34;
    font-size: 16px;
    text-transform: uppercase;
    padding: 10px;
    width: 160px;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    margin: auto;
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%, -50%);
    visibility: visible;
    opacity: 1;
    -webkit-transition: visibility 0.5s, opacity 0.5s linear;
    -moz-transition: visibility 0.5s, opacity 0.5s linear;
    -ms-transition: visibility 0.5s, opacity 0.5s linear;
    -o-transition: visibility 0.5s, opacity 0.5s linear;
    transition: visibility 0.5s, opacity 0.5s linear;
}

.travel_service_item:hover .service_item {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 0.5s, opacity 0.5s linear;
    -moz-transition: visibility 0.5s, opacity 0.5s linear;
    -ms-transition: visibility 0.5s, opacity 0.5s linear;
    -o-transition: visibility 0.5s, opacity 0.5s linear;
    transition: visibility 0.5s, opacity 0.5s linear;
}

.news_item {
    padding: 0 30px 0 5px;
    white-space: normal;
    vertical-align: top;
    max-width: 100% !important;
    width: 236px !important;
    float: none;
}

.news_title {
    color: #808080;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
    white-space: normal;
    width: 100%;
    text-transform: uppercase;
}

.news_body {
    width: 100%;
    white-space: normal;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 24px;
	min-height: 100px;
}

.all_news {
    color: #c0dd34;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    display: block;
    text-align: center;
    margin-top: 95px;
    font-size: 22px;
}

.all_news:hover {
    text-decoration: underline;
}

.homepage_alert_read_more {
    color: #c0dd34;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    /* text-align: right; */
    margin-right: 30px;
    font-size: 13px;
    position: absolute;
    z-index: 99999;
    right: 0;
    bottom: 0;
}

.alert_popup_area {
    display: none;
    position: fixed;
    top:0;
    left:0;
    background: rgba(0,0,0, 0.7);
    width: 100%;
    height: 100%;
    z-index: 999;
}

.close_alert_popup {
    color: #c0dd34;
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 35px;
    font-size: 34px;
    z-index: 999;
}

.expand_alerts {
    width: 60%;
    height: 50%;
    background-color: #fff;
    top: 170px;
    display: none;
    z-index: 999;
    margin: 0 auto;
    left: 50%;
    position: absolute;
    top: 50%;
    -moz-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%, -50%);
	overflow:scroll;
}

.expand_alerts .content_container {
	padding-top: 50px;
}