.footer_query_button, .footer_contact_button {
    border: 1px solid;
    cursor: pointer;
    display: none;
    float: left;
    margin-bottom: 30px;
    padding-bottom: 10px;
    padding-top: 10px;
    text-align: center;
    text-transform: uppercase;
    width: 49%;
}

.footer_contact_button {
    float: right;
}

.button_active {
    color: #000;
    background-color: #c0dd34;
}

.mobile_travel_alerts {
    display: none;
}

.carousel_control_mobile {
    display: none;
}

.nav_mobile {
    cursor: pointer;
    display: none;
    font-size: 29px !important;
    margin-top: -12px;
}

.nav_mobile_area {
    top: 0;
    position: fixed;
    width: 100%;
    background: #464646;
    display: none;
    -webkit-transform: translate3d(0, 0, 0)
}

.nav_mobile_area a {
    border-bottom: 1px solid #c0dd34;
    color: #fff;
    display: none;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: center;
    text-decoration: none;
    width: 270px;
}

.nav_mobile_area a:first-child {
    margin-top: 52px;
}

.body_area {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.mobile_title {
    display: none;
    text-transform: uppercase;
    width: 90%;
    margin: auto;
    color: #c0dd34;
    font-size: 26px;
    font-weight: bold;
    margin-top: 25px;
    margin-bottom: -30px;
}

@media screen and (max-width: 1400px) {
    .travel_service_carousel {
        width: 90%;
    }

    .travel_service_image {
        display: none;
    }

    .mobile_title {
        display: block;
    }

    .travel_service_carousel_item .read_more {
        display: block;
        margin-bottom: 20px;
        text-align: left;
    }

    .carousel_control_mobile {
        display: block;
    }

    .service_prev {
        left: 0 !important;
    }
}

@media screen and (max-width: 1300px) {
    .smart_login_box {
        width: 300px !important;
        display: inline-block;
        float: none;
    }

    .smart_login_area {
        text-align: center;
    }

    .smart_login_area .content_text, .smart_login_area .content_title {
        text-align: left;
    }
}

@media screen and (max-width: 1110px) {
    .homepage_header_content_inside {
        width: 90%;
    }

    .nav_container {
        width: 90%;
    }

    .nav_logo {
        display: none;
    }

    .footer_social_media {
        display: none;
    }

    .back_to_top_button {
        display: none;
    }

    .homepage_travel_update_title {
        font-size: 18px !important;
        margin-bottom: 10px !important;
    }

    .homepage_travel_update_text {
        font-size: 12px !important;
    }

    .travel_service_item {
        width: calc(100% / 2 - 3px) !important;
        margin-bottom: 4px;
    }

    .travel_service_item:nth-of-type(even) {
        margin-right: 0;
    }

}

@media screen and (max-width: 1040px) {
    .homepage_travel_icon {
        margin-left: 0px !important;
        text-align: center !important;
        width: 12% !important;
    }

    .homepage_travel_update_text_area {
        width: 86% !important;
    }

    .alerts_area {
        height: 120px !important;
    }

    .homepage_travel_icon {
        margin-top: 21px !important;
    }
}

@media screen and (max-width: 980px) {
    .navigation_homepage a {
        font-size: 12px;
    }

    .welcome_image {
        display: none;
    }

    .welcome_text {
        width: 100% !important;
    }

    /*.homepage_header_content_area {*/
    /*bottom:50px !important;*/
    /*}*/
    /*.homepage_header {*/
    /*height: 80vh !important;*/
    /*}*/
    .travel_service_carousel_item {
        padding: 0 25px 0 25px;
    }

    .login_form {
        width: 600px;
    }

    .login_form .form_input {
        width: 100% !important;
    }

    .login_form .form_submit {
        width: 200px;
        display: block;
    }
}

@media screen and (max-width: 930px) {
    .query_area {
        float: none;
        margin-bottom: 40px;
        max-width: 100%;
    }

    .query_area h1, .get_in_touch_area h1 {
        display: none;
    }

    .get_in_touch_area {
        display: none;
        float: left;
        margin-bottom: 40px;
        max-width: 100%;
        text-align: left;
        width: 100%;
    }

    .get_in_touch_area .green_outline_button {
        float: none;
        margin: auto;
    }

    .footer_area {
        height: 610px;
    }

    .footer_logo {
        display: none;
        text-align: center;
    }

    .footer_query_button, .footer_contact_button {
        display: block;
    }

    .newsletter_area {
        display: table;
        float: none;
        margin: auto;
        bottom: -235px;

    }

    .query_submit {
        margin: auto;
        display: block;
    }

    .testimonial_text {
        float: left;
        width: 100%;
        margin-top: 40px;
        text-align: left;
    }

    .benefit_left {
        width: 100%;
    }

    .benefit_right {
        float: left;
        margin-bottom: 30px;
    }

    .career_next {
        right: 0;
    }

    .career_prev {
        left: 0;
    }

    .career_next, .career_prev, .alert_next, .alert_prev {
        top: initial !important;
        bottom: 0 !important;
    }

    .history_image, .history_image_fade {
        display: none !important;
    }
}

@media screen and (max-width: 800px) {
    .history_text, .content_text {
        width: 100%;
    }

    .history_image {
        display: none;
    }

    .registration_form {
        width: 96%;
    }

    .career_title {
        font-size: 26px;
    }

    .travel_update_text_area {
        width: 76%;
    }

    .client_lounge_left {
        float: left;
        width: 100%;
    }

    .client_lounge_right {
        float: left !important;
        display: block;
        width: 100% !important;
    }

    .client_lounge_right .green_outline_button {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
    }

    .document_item {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
    }

    .field {
        width: 100%;
    }

    .input input {
        width: 100% !important;
        padding-right: 0;
        padding-left: 0;
        text-indent: 10px;
    }

    .form_submit {
        width: 100%;
    }

    .account_form input {
        width: 100% !important;
        padding-right: 0;
        padding-left: 0;
        text-indent: 10px;
    }

}

@media screen and (max-width: 768px) {
    .nav_button {
        display: none;
    }

    .nav_mobile {
        display: inline-block;
    }

    .nav_mobile_area {
        display: block;
    }

    .nav_contact {
        display: none;
    }

    .smart_form textarea {
        margin: 0;
        width: 100%;
        max-height: none;
        max-width: none;
        float: left;
    }

    .smart_form .form_input {
        width: 95%;
    }

    .static_title {
        font-size: 40px !important;
    }

    .content_one_homepage p {
        font-size: 32px !important;
    }
}

@media screen and (max-width: 730px) {
    .navigation_homepage {
        display: none;
    }

    .homepage_travel_update_title {
        margin-bottom: 0 !important;
    }

    .search_input {
        display: none;
    }
}

@media screen and (max-width: 670px) {
    p, .service_hover_text {
        line-height: 23px;
        font-size: 16px;
    }

    .content_one_homepage p {
        font-size: 35px !important;
        line-height: 44px !important;
    }

    .travel_service_item {
        width: 100% !important;
    }

    /*.homepage_header {*/
    /*height: 470px !important;*/
    /*}*/
    .alerts_area {
        display: none;
    }

    .mobile_travel_alerts {
        display: block;
    }

    .nav_container_homepage {
        padding-top: 20px !important;
    }

    .nav_container_homepage {
        margin-bottom: 40px !important;
    }

    .content_container {
        padding-bottom: 70px !important;
        padding-top: 70px !important;
    }

    .content_title {
        font-size: 30px !important;
        margin-bottom: 30px !important;
    }

    .travel_icon {
        font-size: 60px !important;
        margin-left: 0px !important;
    }

    .travel_update_text {
        font-size: 14px !important;
        margin-bottom: 10px !important;
        min-height: 175px;
    }

    .travel_update_title {
        font-size: 20px !important;
        margin-bottom: 10px !important;
    }

    .homepage_travel_services {
        margin-bottom: 0 !important;
    }

    .news_title {
        font-size: 25px;
    }

    .news_body {
        font-size: 14px;
    }

    .career_next, .career_prev, .alert_next, .alert_prev {
        top: unset !important;
        bottom: 0 !important;
    }

    /*.read_more {*/
    /*display: block;*/
    /*text-align: center;*/
    /*}*/
    .nav_area {
        height: 60vh;
        /*padding-top: 20px;*/
    }

    .page_title {
        width: 100%;
        font-size: 42px;
    }

    .nav_contact {
        display: none;
    }

    .expand_careers .content_container {
        padding-top: 30px !important;
        padding-bottom: 0;
    }

    .quotes {
        height: 50px;
        margin-right: 16px;
        margin-top: 8px;
        width: 63px;
    }

    .career_title {
        font-size: 24px;
    }

    .career_body p {
        font-size: 14px;
    }

    .career_item {
        padding: 0 !important
    }

    .testimonial_title {
        font-size: 30px;
    }

    .testimonial_text p {
        font-size: 14px;
    }

    .testimonial_area {
        height: auto !important;
    }

    .benefit_title {
        font-size: 22px;
    }

    .login_form {
        width: 91%;
    }
}

@media screen and (max-width: 640px) {
    .body_area {
        margin-top: -5px;
    }

    .smart_login_box {
        width: 100% !important;
        margin-right: 0;
    }

    .nav_area_overlay {
        padding-top: 27px;
    }

    .static_title {
        font-size: 27px !important;
        top: -25px !important;
    }
}

@media screen and (max-width: 480px) {

    .content_one_homepage {
        font-size: 26px !important;
    }

    .logo_homepage img {
        width: 180px !important;
    }

    .nav_left {
        font-size: 11px;
    }

    .content_container {
        padding-bottom: 50px !important;
        padding-top: 50px !important;
    }

    .welcome_text {
        font-size: 14px !important;
    }

    .news_item {
        padding: 0 !important;
    }

    .travel_update_text_area {
        width: 77% !important;
    }

    .registration_form {
        width: 91%;
    }

    .smart_form .form_input {
        width: 93%;
    }
}

@media screen and (max-width: 400px) {
    .news_item, .career_item {
        width: 337px !important;
    }
}

@media screen and (max-width: 350px) {
    .service_hover_text {
        width: 90%;
    }

    .news_item, .career_item {
        width: 300px !important;
    }

    .travel_service_title {
        font-size: 22px;
    }

    .travel_service_carousel_item {
        padding: 0 25px 0 0;
    }

    .quotes {
        height: 30px;
        width: 37px;
    }

    .map_pin {
        width: 20px;
    }

}

@media print {
    body {
        font-size: 10pt;
        width: 100%;
    }

    .body_area #content {
        width: 100%;
        height: 100%;
    }

}